<script setup lang="ts"></script>

<template>
    <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.4159 5.5627C12.3461 5.49523 12.2605 5.44479 12.1664 5.41557C12.0723 5.38635 11.9724 5.3792 11.8748 5.39469C11.778 5.41031 11.6864 5.4478 11.6075 5.50412C11.5286 5.56043 11.4646 5.63397 11.4208 5.71871C11.2306 6.03626 10.9986 6.32871 10.7305 6.58875C10.6673 5.01128 10.0686 3.49755 9.0264 2.28053C8.44333 1.70292 7.73844 1.25292 6.95944 0.960986C6.18044 0.669053 5.34552 0.542006 4.51121 0.588446C4.3987 0.588735 4.28838 0.618465 4.192 0.674468C4.09562 0.730471 4.01679 0.81065 3.96391 0.906462C3.91398 1.00029 3.89056 1.10516 3.89602 1.2105C3.90148 1.31585 3.93562 1.41794 3.99501 1.50649C4.08208 1.6325 5.97274 4.44064 3.81465 6.96677C3.26735 6.55275 2.64543 5.89872 2.64543 5.38869C2.6446 5.25433 2.59706 5.12413 2.51041 5.01894C2.42377 4.91375 2.30304 4.83966 2.16756 4.80853C2.03208 4.77741 1.8897 4.79106 1.76322 4.8473C1.63674 4.90353 1.53348 4.99909 1.46998 5.11868C0.620472 6.89752 0.173138 8.83045 0.157715 10.789C0.157715 15.9612 4.14427 17.3053 4.31219 17.3893C4.43272 17.4288 4.56292 17.4317 4.6852 17.3975C4.80748 17.3634 4.91595 17.2939 4.99597 17.1983C5.07599 17.1028 5.1237 16.9859 5.13266 16.8634C5.14162 16.7409 5.11139 16.6187 5.04607 16.5133C4.45607 15.4912 4.22275 14.313 4.3806 13.1531C4.76371 13.8699 5.3339 14.4779 6.03493 14.9172C6.16681 15.0015 6.32707 15.0342 6.48306 15.0086C6.63905 14.983 6.77905 14.9009 6.87453 14.7792C7.73456 13.5651 8.14237 12.1056 8.03132 10.639C8.68434 11.605 9.18189 13.4111 8.26765 16.6393C8.24403 16.7275 8.24156 16.8198 8.26043 16.9092C8.27931 16.9985 8.31903 17.0825 8.37658 17.1548C8.43413 17.2271 8.508 17.2858 8.59258 17.3264C8.67716 17.367 8.77022 17.3886 8.8647 17.3893C8.92031 17.398 8.97702 17.398 9.03262 17.3893C10.4593 16.9285 11.6957 16.0391 12.5608 14.8515C13.4258 13.6638 13.8741 12.2402 13.8401 10.789C13.8401 7.05078 12.559 5.70671 12.4159 5.5627Z"
            fill="currentColor"
        />
    </svg>
</template>
